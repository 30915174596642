import { graphql } from "../../gql";

export const gameMove = graphql(/* GraphQL */ `
  mutation gameMove(
    $id: ID!
    $move: String!
  ) {
    games {
      move(gameID: $id, input: { move: $move }) {
        method
        outcome
      }
    }
  }
`);
