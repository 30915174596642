import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "urql";

import { useFragment } from "../api/gql";
import { getAccountGames } from "../api/core/queries/account.games";
import { GameListConnectionFragment } from "../api/core/fragments/games";

import { Error } from "../components/Errors";
import { Loader } from "../components/Loaders";
import { GameCategory, GameList } from "../components/GameList/GameList";
import { GameListConnectionFragmentFragment } from "../api/gql/graphql";
import Nothing from "../components/Nothing";

type GameCategoryParams = {
  category: GameCategory
}

export const GamesCategory = () => {
  const { category } = useParams<GameCategoryParams>();

  const [result] = useQuery({
    query: getAccountGames,
    variables: {
      page: {
        after: 10,
      },
      skipPagination: false,
      skipGameProgress: true,
      skipGameResult: true,
      skipGameDrawOffer: true,
      skipGameChallenge: true,
      skipActive: category === GameCategory.Active ? false : true,
      skipWaiting: category === GameCategory.Pending ? false : true,
      skipRequests: category === GameCategory.Requests ? false : true,
      skipInvites: category === GameCategory.Invites ? false : true,
    },
  })
  const { data, fetching, error } = result;

  const requests = useFragment(GameListConnectionFragment, data?.account?.me.games.requests)
  const invites = useFragment(GameListConnectionFragment, data?.account?.me.games.invites)
  const waiting = useFragment(GameListConnectionFragment, data?.account?.me.games.waiting)
  const active = useFragment(GameListConnectionFragment, data?.account?.me.games.active)

  const [query, setQuery] = useState<GameListConnectionFragmentFragment | null>();
  useEffect(() => {
    setQuery(() => {
      switch (category) {
        case GameCategory.Active:
          return active
        case GameCategory.Pending:
          return waiting
        case GameCategory.Requests:
          return requests
        case GameCategory.Invites:
          return invites
      }
    })
  }, [data, category, requests, invites, waiting, active]);

  return (
    <>
      {fetching && <Loader />}

      {error && <Error info={error.message} />}

      {!query && !fetching && <Nothing />}

      {
        query &&
        <GameList
          category={category as GameCategory}
          query={query}
          skipTitleClick={true}
          showAvatar="opponent"
        />
      }
    </>
  )
}
export default GamesCategory;