import { useState } from "react"
import { useGameContext } from "../../contexts/GameContext"

import { Button, Dialog, List, ListItem } from "../Adapters"

export const GameDialogButton = () => {
  const [open, setOpen] = useState(false)
  const game = useGameContext()

  const sx = { padding: "15px 20px" }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFlip = () => {
    game.flip()
    setOpen(false)
  }

  const handleCancel = () => {
    game.cancel()
    setOpen(false)
  }

  const handleResign = () => {
    game.resign()
    setOpen(false)
  }

  return (
    <>
      {
        game.isActive &&
        <>
          <Button variant="outlined" onClick={handleClickOpen}>Actions</Button>
          <Dialog open={open} onClose={handleClose}>
            <List sx={{ width: "250px" }}>
              <ListItem button onClick={handleFlip} sx={sx}>
                Flip Board
              </ListItem>
              {
                !game.isStarted &&
                <ListItem button onClick={handleCancel} sx={sx}>
                  Cancel
                </ListItem>
              }
              {
                game.isStarted &&
                <ListItem button onClick={handleResign} sx={sx}>
                  Resign
                </ListItem>
              }
            </List>
          </Dialog>
        </>
      }
    </>
  )
}
