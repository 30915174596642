import { useNavigate } from "react-router-dom";

import { GamePlayerFragmentFragment } from "../../api/gql/graphql"

import { useGameContext } from "../../contexts/GameContext";
import { useUserContext } from "../../contexts/UserContext";

import { Button, Grid, IconButton, Typography } from "../Adapters";

import { NoAvatar, UserAvatar } from "../Avatars";
import { GameDialogButton } from "./GameDialog";

interface Props {
  data: GamePlayerFragmentFragment;
}

export const GamePlayerComponent = ({ data }: Props) => {
  const navigate = useNavigate()
  const user = useUserContext()

  return (
    <Grid container rowSpacing={0}>
      <Grid xs={2}>
        <IconButton onClick={() => navigate(`/users/${data.id}`)}>
          <UserAvatar url={data.small?.resize} username={data.username} />
        </IconButton>
      </Grid>
      <Grid xs={6} sx={{ paddingLeft: "10px" }}>
        <Typography>{data.displayName}</Typography>
      </Grid>
      {
        data.id === user.id &&
        <Grid xs={4} textAlign="end" alignSelf="center">
          <GameDialogButton />
        </Grid>
      }
    </Grid>
  )
}

export const NoGamePlayer = () => {
  const game = useGameContext()

  return (
    <Grid container rowSpacing={0} columnSpacing={1} flex={1} sx={{ alignItems: "center" }}>
      <Grid xs={2}>
        <NoAvatar />
      </Grid>
      <Grid xs={10} flex={1} textAlign="end">
        {
          !game.isAuthor && game.isActive &&
          <Button variant="outlined" onClick={game.join}>
            Join
          </Button>
        }
      </Grid>
    </Grid>
  )
}
