import React, { useContext } from 'react';

import { Alert, Snackbar } from '../components/Adapters';

interface ContextProps {
  error: (msg?: string) => void;
  info: (msg?: string) => void;
  success: (msg?: string) => void;
}

export const SnackbarContext = React.createContext<ContextProps>({} as ContextProps);

export const useSnackbarContext = () => useContext(SnackbarContext);

interface Props {
  children?: React.ReactNode;
}

interface StateProps {
  message?: string;
  severity: "error" | "warning" | "info" | "success";
  open: boolean;
}

const SnackbarProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = React.useState<StateProps>({
    message: "",
    severity: "error",
    open: false,
  })

  const error = (msg?: string | null) => {
    setState({
      message: msg || "Oh no ... something went wrong!",
      severity: "error",
      open: true,
    })
  }

  const info = (msg?: string) => {
    setState({
      message: msg || "Info",
      severity: "info",
      open: true,
    })
  }

  const success = (msg?: string) => {
    setState({
      message: msg || "Success",
      severity: "success",
      open: true,
    })
  }

  const close = () => {
    setState({ ...state, open: false })
  }

  return (
    <SnackbarContext.Provider value={{
      error,
      info,
      success,
    }}>
      {children}
      <Snackbar
        open={state.open}
        onClose={close}
        autoHideDuration={2000}
        message={state.message}
        key={state.severity}
      >
        <Alert
          severity={state.severity}
          onClose={close}
          sx={{ width: '100%' }}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
export default SnackbarProvider
