import moment from "moment"

import { useUserContext } from "../contexts/UserContext"

import { Stack, Typography } from "../components/Adapters"
import { UserAvatar } from "../components/Avatars"
import UserRecentGames from "../components/UserRecentGames"

const AccountView = () => {
  const user = useUserContext()

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={3} className="layout main">
        <UserAvatar url={user.avatar} username={user.username} size={90} />
        <Stack direction="column" spacing={1}>
          <Typography variant="h5">{user.displayName}</Typography>
          <Typography variant="subtitle1">@{user.username}</Typography>
          <Typography variant="subtitle2">{moment(user.createdAt).format("MMM Do, YYYY")}</Typography>
        </Stack>
      </Stack>
      <UserRecentGames
        showHeader
        userId={user.id}
      />
    </Stack>
  )
}
export default AccountView
