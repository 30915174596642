import { PaletteMode, ThemeOptions } from "@mui/material";

const baseTheme: ThemeOptions = {
  palette: {
    // primary: {
    //   main: "var(--tg-theme-secondary-bg-color, #1976d2)",
    //   light: "var(--tg-theme-secondary-bg-color, red)",
    //   dark: "var(--tg-theme-secondary-bg-color, red)",
    // },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundImage: "none",
          // backgroundColor: "var(--tg-theme-secondary-bg-color, #1976d2)",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          height: "50px",
          borderRadius: "8px",
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: "60px",
          alignItems: "center"
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        // root: {
        //   color: "var(--tg-theme-text-color)"
        // }
      }
    }
  }
}

export const getMainTheme = (mode: PaletteMode) => ({
  ...baseTheme,
  palette: {
    mode,
    ...(
      mode === 'light'
        ? {}
        : {}
    ),
  },
})
