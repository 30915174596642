import { graphql } from "../../gql";

export const getOpenGames = graphql(/* GraphQL */ `
  query getOpenGames(
    $skipPagination: Boolean = false
    $page: PageInput! = { after: 10 }

    $excludePlayers: [ID!]

    $skipGameAuthor: Boolean = false
    $skipGamePlayers: Boolean = false
    $skipGamePlayerRatings: Boolean = false
    $skipGameProgress: Boolean = true
    $skipGameResult: Boolean = true
    $skipGameDrawOffer: Boolean = true
    $skipGameChallenge: Boolean = true
    $skipGameTiming: Boolean = false
  ) {
    games {
      search(
        input: {
          page: $page
          inStatuses: [ NEW ]
          excludePlayers: $excludePlayers
          skipChallenges: true
        }
      ) {
        ...GameListConnectionFragment
      }
    }
  }
`)

export const getOpenGamesTotal = graphql(/* GraphQL */ `
  query getOpenGamesTotal(
    $page: PageInput! = { after: 1 }
    $excludePlayers: [ID!]
  ) {
    games {
      search(
        input: {
          page: $page
          inStatuses: [ NEW ]
          excludePlayers: $excludePlayers
          skipChallenges: true  
        }
      ) {
        totalCount
      }
    }
  }
`)
