import { useNavigate } from "react-router"

import {
  GameFullFragmentFragment,
  GameListConnectionFragmentFragment,
} from "../../api/gql/graphql"

import {
  List,
  Grid,
  Stack,
  Typography,
} from "../Adapters"

import { IconArrowRight } from "../Icons"
import { useUserContext } from "../../contexts/UserContext"
import GameListActiveItem from "./ActiveItem"

export enum GameCategory {
  Active = "Active",
  Pending = "Pending",
  Requests = "Challenges",
  Invites = "Invites",
}

export const GameList = (props: {
  category?: GameCategory;
  skipTitleClick?: boolean;
  query: GameListConnectionFragmentFragment;
  showAvatar?: "author" | "opponent" | undefined;
}) => {
  const navigate = useNavigate()
  const user = useUserContext()

  const handleClick = () => {
    navigate(`/games/category/${props.category}`)
  }

  return (
    <Grid container rowSpacing={1} textAlign="left">
      {
        // List title
        props.category === undefined
          ? <></>
          : <Grid xs={12} className="layout top horizontal">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={props.skipTitleClick === undefined ? handleClick : () => { }}
            >
              <Typography variant="h6">{props.category}</Typography>
              {!props?.skipTitleClick ? <IconArrowRight /> : <></>}
            </Stack>
          </Grid>
      }

      {
        // List of games
        props.query.edges && props.query.edges.length === 0
          ? <></>
          : <Grid xs={12}>
            <List>
              {
                props.query.edges?.map((e, i) => (
                  <GameListActiveItem
                    key={i}
                    actorId={user.id}
                    data={e.node as GameFullFragmentFragment}
                    showAvatar={props.showAvatar} />
                ))
              }
            </List>
          </Grid>
      }
    </Grid>
  )
}
