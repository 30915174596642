import React from "react";
import { useQuery } from "urql";

import { useFragment } from "../api/gql";
import { getAccountGames } from "../api/core/queries/account.games";
import { GameListConnectionFragment } from "../api/core/fragments/games";

import { Loader } from "../components/Loaders";
import { Error } from "../components/Errors";
import { GameCategory, GameList } from "../components/GameList/GameList";
import SearchEmptyResult from "../components/GameList/SearchEmptyResult";

const MyGames = () => {
  const [result] = useQuery({
    query: getAccountGames,
    variables: {
      page: {
        after: 5,
        skipTotal: true,
      },
      skipPagination: true,
      skipGameProgress: true,
      skipGameResult: true,
      skipGameDrawOffer: true,
      skipGameChallenge: true,
    },
  })
  const { data, fetching, error } = result;

  const requests = useFragment(GameListConnectionFragment, data?.account?.me.games.requests)
  const invites = useFragment(GameListConnectionFragment, data?.account?.me.games.invites)
  const waiting = useFragment(GameListConnectionFragment, data?.account?.me.games.waiting)
  const active = useFragment(GameListConnectionFragment, data?.account?.me.games.active)

  const isEmptyResult = () => {
    if (!fetching && !error) {
      if (requests && invites && waiting && active) {
        if (requests.edges && requests.edges.length > 0) return false
        if (invites.edges && invites.edges.length > 0) return false
        if (waiting.edges && waiting.edges.length > 0) return false
        if (active.edges && active.edges.length > 0) return false

        return true
      }
    }
    return false
  }

  if (fetching) return <Loader />

  if (error) return <Error info={error.message} />

  if (isEmptyResult()) return (
    <SearchEmptyResult />
  )

  return (
    <React.Fragment>
      {
        requests?.edges && requests.edges.length > 0 &&
        <GameList
          category={GameCategory.Requests}
          query={requests}
          showAvatar="opponent"
        />
      }

      {
        invites?.edges && invites.edges.length > 0 &&
        <GameList
          category={GameCategory.Invites}
          query={invites}
          showAvatar="opponent"
        />
      }

      {
        waiting?.edges && waiting.edges.length > 0 &&
        <GameList
          category={GameCategory.Pending}
          query={waiting}
          showAvatar="opponent"
        />
      }

      {
        active?.edges && active.edges.length > 0 &&
        <GameList
          category={GameCategory.Active}
          query={active}
          showAvatar="opponent"
        />
      }
    </React.Fragment>
  )
};
export default MyGames;