import { graphql } from "../../gql";

export const authSignUp = graphql(/* GraphQL */ `
  mutation authSignUp(
    $initData: MapString! = {}
    $signUpPaylod: AuthSignUpPayloadInput!
  ) {
    auth {
      loginByProvider(
        provider: TELEGRAM
        input: {
          payload: {
            initData: $initData
          }
          signUp: true
          signUpPayload: $signUpPaylod
        }
      ) {
        tokens {
          access
          refresh
        }
      }
    }
  }
`);
