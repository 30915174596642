import { graphql } from "../../gql";

export const gameChallenge = graphql(/* GraphQL */ `
  mutation gameChallenge(
    $userId: ID!
    
    $input: GameCreateInput!
    $timing: GameTimingInput!

    $skipGameAuthor: Boolean = true
    $skipGameChallenge: Boolean = true
    $skipGameDrawOffer: Boolean = true
    $skipGamePlayers: Boolean = false
    $skipGameProgress: Boolean = false
    $skipGameResult: Boolean = true
    $skipGamePlayerRatings: Boolean = true
    $skipGameTiming: Boolean = false
  ) {
    games {
      challengeCreate(
        userID: $userId
        input: $input
        timing: $timing
      ) {
        ...GameFullFragment
      }
    }
  }
`);
