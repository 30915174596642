import { useNavigate } from "react-router-dom"

import { IconBack } from "../Icons"
import { IconButton } from "../Adapters"

const BackButton = () => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate(-1)
  }

  return (
    <IconButton color="inherit" onClick={onClick}>
      <IconBack />
    </IconButton>
  )
}
export default BackButton;
