import { useEffect, useState } from "react";

import Chessground from "@react-chess/chessground";
import { Key } from "chessground/types";
import { Config } from "chessground/config";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";
import "chessground/assets/chessground.cburnett.css";

import { GameSideEnum } from "../../api/gql/graphql";
import { useGameContext } from "../../contexts/GameContext";

export const GameBoard = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, []);

  const game = useGameContext()

  const mapSide = (side: GameSideEnum) => {
    return side === GameSideEnum.White
      ? "white"
      : "black"
  }

  const [config, setConfig] = useState<Config>({
    fen: game.board,
    orientation: mapSide(game.orientation),
    turnColor: mapSide(game.current),
    animation: {
      enabled: true,
      duration: 200,
    },
    draggable: {
      enabled: false,
    },
    selectable: {
      enabled: true,
    },
    highlight: {
      lastMove: true,
      check: true
    },
    movable: {
      free: true,
      color: mapSide(game.player),
      showDests: true,
    },
    premovable: {
      enabled: false,
    },
    events: {
      change() {
        console.log({
          "orientation": game.orientation,
          "current": game.current,
          "game": game,
        })
      },
      move(orig, dest, capturedPiece?) {
        game.move(orig + dest)
      },
    },
  })

  const lastMove = (): Key[] => {
    console.log(game.lastMove)

    if (game.lastMove.length > 0) {
      return [
        game.lastMove[0] as Key,
        game.lastMove[1] as Key,
      ]
    }
    return []
  }

  useEffect(() => {
    setConfig({
      ...config,
      fen: game.board,
      turnColor: mapSide(game.current),
      orientation: mapSide(game.orientation),
      lastMove: lastMove(),
    })
  }, [game.board, game.orientation, game.current])

  // useEffect(() => {
  //   setConfig({
  //     ...config,
  //     lastMove: lastMove(),
  //   })
  // }, [game.lastMove])

  return (
    <div style={{ overflow: "hidden" }}>
      <Chessground
        width={width}
        height={width}
        config={config}
      />
    </div>
  );
}
