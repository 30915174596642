import { Typography } from "@mui/material";

import { Stack } from "../Adapters"

const Timing = (props: {
  initial: number,
  bonus: number,
}) => {

  if (props.initial !== 0 && props.bonus !== 0) {
    return (
      <Stack direction="row" spacing={1}>
        <span>{props.initial}</span>
        <span>|</span>
        <span>{props.bonus}</span>
      </Stack>
    )
  }

  if (props.initial !== 0) {
    let value = props.initial;
    let period = "min";

    if (props.initial % 60 === 0) {
      value = props.initial / 60
      period = "hours"
    }

    if (props.initial % 1440 === 0) {
      value = props.initial / 1440
      period = "days"
    }

    return (
      <Stack direction="row" spacing={1}>
        <Typography variant="body2">{value} {period}</Typography>
      </Stack>
    )
  }

  return (
    <></>
  )
}
export default Timing;
