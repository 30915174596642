import { useState } from "react";
import { GameMethodEnum, GameOutcomeEnum, GamePlayerFragmentFragment } from "../../api/gql/graphql";

import { useGameContext } from "../../contexts/GameContext";

import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "../Adapters";
import { UserAvatar } from "../Avatars";

const GameResult = () => {
  const data = useGameContext()
  const [result] = useState(data.game?.result)

  const [open, setOpen] = useState(result ? true : false)

  const [white] = useState(data.game?.players.white as GamePlayerFragmentFragment)
  const [black] = useState(data.game?.players.black as GamePlayerFragmentFragment)

  const handleClose = () => setOpen(false)

  const outcomeString = () => {
    switch (result?.outcome) {
      case GameOutcomeEnum.OutcomeDraw:
        return "Draw"
      case GameOutcomeEnum.OutcomeWhite:
        return "White Wins"
      case GameOutcomeEnum.OutcomeBlack:
        return "Black Wins"
    }
  }

  const methodString = (): string => {
    let out = []

    switch (result?.outcome) {
      case GameOutcomeEnum.OutcomeDraw:
        out.push("drawn"); break;
      case GameOutcomeEnum.OutcomeWhite:
        out.push(white.username, "won"); break;
      case GameOutcomeEnum.OutcomeBlack:
        out.push(black.username, "won"); break;
      default:
        break
    }

    switch (result?.method) {
      case GameMethodEnum.Resignation:
        out.push("by resignation"); break;
      case GameMethodEnum.Checkmate:
        out.push("by checkmate"); break;
      case GameMethodEnum.Stalemate:
        out.push("by stalemate"); break;
      case GameMethodEnum.DrawOffer:
        out.push("by draw offer"); break;
      default:
        break
    }
    return out.join(" ")
  }

  return (
    <Dialog
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 435, borderRadius: "12px" } }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle textAlign="center">
        <Typography variant="h5" component="h1">
          {outcomeString()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack flex={1} direction="column" spacing={3} textAlign="center">
          <Typography>
            {methodString()}
          </Typography>
          <Stack direction="row" justifyContent="space-around" alignItems="center">
            <Stack direction="column" spacing={2} alignItems="center">
              <UserAvatar
                url={white.small?.resize}
                username={white.username}
                size={80}
              />
              <Typography>{white.displayName}</Typography>
            </Stack>

            {/* {data?.game && IconMappingGameType(data.game.type)} */}

            <Stack direction="column" spacing={2} alignItems="center">
              <UserAvatar
                url={black.small?.resize}
                username={black.username}
                size={80}
              />
              <Typography>{black.displayName}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
export default GameResult;
