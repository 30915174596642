import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "urql";

import { getUserProfile } from "../api/core/queries/users.get";
import { UserFullFragmentFragment } from "../api/gql/graphql";

import UserRecentGames from "../components/UserRecentGames";
import { Stack, Typography } from "../components/Adapters";
import { UserAvatar } from "../components/Avatars";
import { Loader } from "../components/Loaders";
import { Error } from "../components/Errors";
import moment from "moment";

type UserProfileParams = {
  id: string;
}

const UserProfile = () => {
  const { id } = useParams<UserProfileParams>();

  const [result] = useQuery({
    query: getUserProfile,
    variables: {
      id: id!,
    }
  })
  const { data, fetching, error } = result;

  const [user, setUser] = useState<UserFullFragmentFragment>({} as UserFullFragmentFragment)

  useEffect(() => {
    let user = data?.users?.get
    if (user) setUser(user as UserFullFragmentFragment)
  }, [data])

  return (
    <>
      {fetching && <Loader />}
      {error && <Error info={error.message} />}
      {data &&
        <Stack direction="column">
            <Stack direction="row" spacing={3} className="layout main">
              <UserAvatar url={user.avatar?.default} username={user.username} size={90} />
              <Stack direction="column" spacing={1}>
                <Typography variant="h5">{user.displayName}</Typography>
                <Typography variant="subtitle1">@{user.username}</Typography>
                <Typography variant="subtitle2">{moment(user.createdAt).format("MMM Do, YYYY")}</Typography>
              </Stack>
            </Stack>
          <UserRecentGames
            showHeader
            userId={user.id}
          />
        </Stack>
      }
    </>
  )
}
export default UserProfile;
