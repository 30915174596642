import { graphql } from "../../gql";

export const authRefresh = graphql(/* GraphQL */ `
  mutation authRefresh(
    $token: String!
  ){
    auth {
      refresh(token: $token) {
        tokens {
          access
          refresh
        }
      }
    }
  }
`);
