import { useNavigate } from "react-router";

import {
  GameFullFragmentFragment,
  GameOutcomeEnum,
  GamePlayerFragmentFragment,
} from "../../api/gql/graphql";

import { Grid, ListItemButton, Stack, Typography } from "../Adapters";
import { NoAvatar, UserAvatar } from "../Avatars";
import { IconDraw, IconLose, IconMappingGameType, IconWin } from "../Icons";

const GameEndedListItem = (props: {
  data: GameFullFragmentFragment;
  actorId: string;
  showAvatar?: "author" | "opponent" | undefined;
}) => {
  const navigate = useNavigate()

  const game = props.data;

  const author = game.author as GamePlayerFragmentFragment;
  const white = game.players?.white as GamePlayerFragmentFragment;
  const black = game.players?.black as GamePlayerFragmentFragment;

  const handleClick = () => {
    navigate(`/games/${game.id}`)
  }

  const getPlayer = () => {
    if (props.showAvatar === "author" && author) return author

    if (props.showAvatar === "opponent") {
      if (white && props.actorId !== white.id) { return white }
      if (black && props.actorId !== black.id) { return black }
    }

    if (white) return white
    if (black) return black

    return null
  }

  const player = getPlayer()

  const outcomeIcon = () => {
    if (game.result?.outcome === GameOutcomeEnum.OutcomeDraw) {
      return IconDraw
    }

    if (game.result?.outcome === GameOutcomeEnum.OutcomeWhite && props.actorId === white.id) {
      return IconWin
    }
    return IconLose
  }

  return (
    <ListItemButton onClick={handleClick}>
      <Grid container xs alignItems="center">
        <Grid xs={2}>
          {
            player
              ? <UserAvatar url={player.small?.resize} username={player.username} />
              : <NoAvatar />
          }
        </Grid>
        <Grid xs={9}>
          <Stack direction="column" spacing={1} alignItems="start" sx={{paddingLeft: "15px"}}>
            <Typography>{player?.displayName}</Typography>
            {IconMappingGameType(game.type)}
          </Stack>
        </Grid>
        <Grid xs={1}>
          {outcomeIcon()}
        </Grid>
      </Grid>
    </ListItemButton>
  )
}
export default GameEndedListItem;
