import { Link } from "react-router-dom";
import { Button, Stack, Typography } from "./Adapters";

const Nothing = (props: {
  showGameActions?: boolean,
}) => {
  return (
    <Stack
      justifyContent="space-around"
      alignItems="center"
      style={{
        height: "50vh",
      }}
    >
      <Typography>
        Sorry, but we dont find anyting ...
      </Typography>
      {
        props.showGameActions &&
        <Stack direction="column" alignItems="center" spacing={5}>
          <Button variant="outlined" size="large" component={Link} to="/games/create">Start game</Button>
        </Stack>
      }
    </Stack>
  )
}
export default Nothing;
