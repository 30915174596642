import { graphql } from "../../gql";

export const gamesMovesProcessed = graphql(/* GraphQL */ `
  subscription gamesMovesProcessed(
      $id: ID!

      $skipGameAuthor: Boolean = false
      $skipGameChallenge: Boolean = false
      $skipGameDrawOffer: Boolean = false
      $skipGamePlayers: Boolean = false
      $skipGameProgress: Boolean = false
      $skipGameResult: Boolean = false
      $skipGamePlayerRatings: Boolean = true
      $skipGameTiming: Boolean = false
    ) {
    gamesMovesProcessed(gameID: $id) {
      ...GameFullFragment
    }
  }
`);
