export const Error = (props: {
    title?: string;
    message?: string;
    info?: string;
}) => (
	<section className="error">
		<h2>{props.title || "Error"}</h2>
        <p>{props.message || "Oh no ..."}</p>
        <p>{props.info}</p>
	</section>
);
