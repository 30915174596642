import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faUser, faUsers,
    faAngleRight, faAngleLeft, faAngleDown,
    faBars, faHome, faSearch, faClose,
    faSun, faStopwatch, faBolt,
    faDice, faChessBoard,
    faChess, faChessKing, faChessBishop, faChessKnight, faChessPawn, faChessQueen, faChessRook,
    faCircleQuestion,
    faSquareCheck, faSquareXmark, faSquare,
    faGamepad,
 } from '@fortawesome/free-solid-svg-icons'

library.add(
    faUser, faUsers,
    faAngleRight, faAngleLeft, faAngleDown,
    faBars, faHome, faSearch, faClose,
    faSun, faStopwatch, faBolt,
    faDice, faChessBoard,
    faChess, faChessKing, faChessBishop, faChessKnight, faChessPawn, faChessQueen, faChessRook,
    faCircleQuestion,
    faSquareCheck, faSquareXmark, faSquare,
    faGamepad,
)
