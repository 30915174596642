import { useEffect, useState } from "react";
import { useQuery } from "urql";

import { userSearchSignup } from "../../api/core/queries/users.search";

import { useAuthContext } from "../../contexts/AuthContext"
import { useTelegramWebApp } from "../../contexts/telegram-webapp";

import { Box, Button, Stack, TextField } from "../Adapters"
import { ContentLayout } from "../Layouts";

const AuthSignUp = () => {
  const auth = useAuthContext();
  const telegram = useTelegramWebApp();

  const [search, setSearch] = useState(telegram.initDataUnsafe.user?.username || "");
  const [exists, setExists] = useState(false);

  const [userSearchResponse, userSearchExec] = useQuery({
    query: userSearchSignup,
    variables: {
      username: search,
    },
  })

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearch(event.target.value);
    userSearchExec();
  }

  const onSignupClick = () => {
    auth.signUp({
      username: search,
    })
  }

  useEffect(() => {
    let edges = userSearchResponse.data?.users?.search.edges || []

    if (edges.length > 0) {
      setExists(true);
      return
    }
    setExists(false)
  }, [userSearchResponse.data?.users?.search]);

  return (
    <ContentLayout>
      <Stack direction="column" spacing={3}>
        <Box style={{ paddingTop: "20px" }}>
          Hello my friend
        </Box>
        <TextField
          error={exists}
          id="username-input"
          variant="outlined"
          label="Username"
          onChange={onChange}
          defaultValue={telegram.initDataUnsafe.user?.username}
          helperText={exists ? "An account with the same username already exists" : ""}
        />
        <Button
          disabled={exists}
          variant="contained"
          onClick={onSignupClick}
          sx={{
            height: "60px",
          }}
        >
          SignUp
        </Button>
      </Stack>
    </ContentLayout>
  )
}
export default AuthSignUp;
