import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "urql";

import { getUserRecentGames } from "../api/core/queries/user.games";
import {
  GameFullFragmentFragment,
  GameListConnectionFragmentFragment,
} from "../api/gql/graphql";

import { List, ListItem, ListItemButton, Stack, Typography } from "./Adapters";
import { IconArrowRight } from "./Icons";
import { Error } from "./Errors";
import { Loader } from "./Loaders";
import GameEndedListItem from "./GameList/EndedItem";

const UserRecentGames = (props: {
  userId: string,
  showHeader?: boolean,
  onPage?: number,
}) => {
  const navigate = useNavigate();

  const [result] = useQuery({
    query: getUserRecentGames,
    variables: {
      userId: props.userId,
      page: {
        after: props.onPage,
      },
    },
  })
  const { data, fetching, error } = result;

  const [games, setGames] = useState<GameListConnectionFragmentFragment>({} as GameListConnectionFragmentFragment);

  useEffect(() => {
    let games = data?.users?.get.games.finished
    if (games) {
      setGames(games as GameListConnectionFragmentFragment)
    }
  }, [data])

  return (
    <List>
      <ListItem></ListItem>
      {
        props.showHeader &&
        <ListItemButton
          disableGutters
          className="layout main bottom"
          onClick={() => navigate(`games`)}
        >
          <Stack direction="row" flex={1} alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Recent Games</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>{games.totalCount || 0}</Typography>
              <IconArrowRight />
            </Stack>
          </Stack>
        </ListItemButton>

      }

      {fetching && <Loader />}
      {error && <Error info={error.message} />}

      {games.edges?.map((e, i) => {
        let node = e.node as GameFullFragmentFragment;
        return (
          <GameEndedListItem
            key={`${i}`}
            data={node}
            actorId={props.userId}
            showAvatar="opponent"
          />
        )
      })}
    </List>
  )
}
export default UserRecentGames;
