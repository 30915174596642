import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../contexts/AuthContext";

const SignIn = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      auth.signIn();
      return
    }
    navigate("/")
  }, [auth.isAuthenticated]);

  return (
    <></>
  )
};
export default SignIn;
