import { Box } from './Adapters';

export const ContentLayout = (props: {
  children: JSX.Element;
}) => {
  return (
    <Box padding="15px 25px">
      {props.children}
    </Box>
  )
}
