import { graphql } from "../../gql";

export const getUserRecentGames = graphql(/* GraphQL */ `
  query getUserRecentGames(
    $page: PageInput! = { after: 10 }
    $skipPagination: Boolean = false

    $userId: ID!

    $skipGameAuthor: Boolean = true
    $skipGamePlayers: Boolean = false
    $skipGamePlayerRatings: Boolean = true
    $skipGameProgress: Boolean = true
    $skipGameResult: Boolean = true
    $skipGameDrawOffer: Boolean = true
    $skipGameChallenge: Boolean = true
    $skipGameTiming: Boolean = true
  ) {
    users {
      get(id: $userId) {
        games {
          finished(input: {
            page: $page
          }) {
            ...GameListConnectionFragment
          }
        }
      }
    }
  }
`);
