import { graphql } from "../../gql";

export const getAccountGames = graphql(/* GraphQL */ `
  query getAccountGames(
    # skips games by stauses
    $skipWaiting: Boolean = false
    $skipActive: Boolean = false
    $skipRequests: Boolean = false
    $skipInvites: Boolean = false

    # pagintations
    $skipPagination: Boolean = false
    $page: PageInput = { after: 5 }

    # skip some game resolvers
    $skipGameAuthor: Boolean = true
    $skipGameProgress: Boolean = false
    $skipGameResult: Boolean = false
    $skipGamePlayers: Boolean = false
    $skipGameDrawOffer: Boolean = false
    $skipGameChallenge: Boolean = false
    $skipGamePlayerRatings: Boolean = true
    $skipGameTiming: Boolean = false
  ) {
    account {
      me {
        id
        games {
          waiting(input: { page: $page } ) @skip(if: $skipWaiting) {
            ...GameListConnectionFragment
          }
          active(input: { page: $page }) @skip(if: $skipActive) {
            ...GameListConnectionFragment
          }
          requests(input: { page: $page }) @skip(if: $skipRequests) {
            ...GameListConnectionFragment
          }
          invites(input: { page: $page }) @skip(if: $skipInvites) {
            ...GameListConnectionFragment
          }
        }
      }
    }
  }
`);
