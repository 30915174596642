import { graphql } from "../../gql";

export const getGame = graphql(/* GraphQL */ `
  query getGame(
    $id: ID!

    $skipGameAuthor: Boolean = false
    $skipGameChallenge: Boolean = false
    $skipGameDrawOffer: Boolean = false
    $skipGamePlayers: Boolean = false
    $skipGameProgress: Boolean = false
    $skipGameResult: Boolean = false
    $skipGamePlayerRatings: Boolean = false
    $skipGameTiming: Boolean = false    
  ) {
    games {
      get(id: $id) {
        ...GameFullFragment
      }
    }
  }
`);

export const GetGameRequest = (id: string) => {
  return {
    query: getGame,
    variables: {
      id: id,
      skipGameProgress: false,
      skipGameResult: false,
      skipGameDrawOffer: false,
      skipGameChallenge: false,
    },
  }
}
