import { useNavigate } from "react-router";

import {
  GameFullFragmentFragment,
  GamePlayerFragmentFragment,
} from "../../api/gql/graphql";

import { ListItem, ListItemAvatar, ListItemButton, Stack, Typography } from "../Adapters";
import { NoAvatar, UserAvatar } from "../Avatars";
import { IconMappingGameType } from "../Icons";
import Timing from "../Game/Timing";

const GameListActiveItem = (props: {
  data: GameFullFragmentFragment;
  actorId: string;
  showAvatar?: "author" | "opponent" | undefined;
}) => {
  const navigate = useNavigate()

  const game = props.data;

  const author = game.author as GamePlayerFragmentFragment;
  const white = game.players?.white as GamePlayerFragmentFragment;
  const black = game.players?.black as GamePlayerFragmentFragment;

  const handleClick = () => {
    navigate(`/games/${game.id}`)
  }

  const getPlayer = () => {
    if (props.showAvatar === "author" && author) return author

    if (props.showAvatar === "opponent") {
      if (white && props.actorId !== white.id) { return white }
      if (black && props.actorId !== black.id) { return black }
    }

    if (white) return white
    if (black) return black

    return null
  }

  const player = getPlayer()

  return (
    <ListItem disableGutters>
      <ListItemButton onClick={handleClick}>
        <ListItemAvatar sx={{ paddingRight: "10px" }}>
          {
            player
              ? <UserAvatar url={player.small?.resize} username={player.username} />
              : <NoAvatar />
          }
        </ListItemAvatar>
        <Stack direction="column" spacing={1}>
          <Typography>{player?.displayName}</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            {IconMappingGameType(game.type)}
            <Timing
              initial={game.timing?.initial || 0}
              bonus={game.timing?.bonus || 0}
            />
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  )
}
export default GameListActiveItem;
