import { useState } from "react";
import { Outlet, } from "react-router";

import { Badge, Box, Tab, TabContext, TabList, TabPanel, Typography } from "../components/Adapters";
import GameCreateFormProvider from "../components/GameCreate/FormContext";
import { GameCreateForm } from "../components/GameCreate/CreateForm";
import GamesOpen from "../components/GameList/GamesOpen";


export const GameCreateMain = () => {
  return (
    <GameCreateFormProvider>
      <Outlet />
    </GameCreateFormProvider>
  )
}

export const GameCreateTab = () => {
  const [value, setValue] = useState("1")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} variant="fullWidth">
            <Tab
              value="1"
              label={
                <Typography>Custom</Typography>
              }
              sx={{ textTransform: "none" }}
            />
            <Tab
              value="2"
              label={
                <Badge color="primary" variant="dot" sx={{padding: "0 10px"}}>
                  <Typography>Open Challenges</Typography>
                </Badge>
              }
              sx={{ textTransform: "none" }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <GameCreateForm />
        </TabPanel>
        <TabPanel value="2">
          <GamesOpen />
        </TabPanel>
      </TabContext>
    </Box>
  )
};
