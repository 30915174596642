import { graphql } from "../../gql";

  export const gameJoin = graphql(/* GraphQL */ `
  mutation gameJoin(
    $id: ID!

    $skipGameAuthor: Boolean = true
    $skipGameChallenge: Boolean = false
    $skipGameDrawOffer: Boolean = false
    $skipGamePlayers: Boolean = false
    $skipGameProgress: Boolean = false
    $skipGameResult: Boolean = true
    $skipGamePlayerRatings: Boolean = true
    $skipGameTiming: Boolean = false
  ) {
    games {
      join(gameID: $id) {
        ...GameFullFragment
      }
    }
  }
`);
