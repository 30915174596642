import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

type Action = "menu" | "back" | "close"

interface ContextProps {
  title?: string
  setTitle: (title: string) => void

  action: Action
  setAction: (action: Action) => void
};

export const AppBarContext = React.createContext<ContextProps>({} as ContextProps);

export const useAppBarContext = () => useContext(AppBarContext);

interface Props {
  children?: React.ReactNode;
}

const AppBarProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation()

  const [title, setTitle] = useState("")
  const [action, setAction] = useState<Action>("menu")

  useEffect(() => {
    if (location.pathname === "/") {
      setAction("menu")
      return
    }
    setAction("back")
  }, [location])

  return (
    <AppBarContext.Provider
      value={{
        "title": title,
        "setTitle": setTitle,

        "action": action,
        "setAction": setAction,
      }}
    >
      {children}
    </AppBarContext.Provider>
  )
}
export default AppBarProvider
