import { Link } from "react-router-dom";
import { Button, Stack, Typography } from "../Adapters";

import { ReactComponent as SearchSvg } from '../../assets/search.svg';

const SearchEmptyResult = (props: {
  text?: string,
}) => {
  return (
    <Stack
      justifyContent="space-around"
      alignItems="center"
      style={{
        height: "50vh",
        paddingTop: "15px",
      }}
    >
      <SearchSvg className="search svg" />
      <Typography>{props.text || "No active games..."}</Typography>
      {
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button variant="contained" size="large" component={Link} to="/games/create">Create</Button>
          <Typography>or</Typography>
          <Button variant="outlined" size="large" component={Link} to="/games/create">Join</Button>
        </Stack>
      }
    </Stack>
  )
}
export default SearchEmptyResult;
