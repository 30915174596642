import { Avatar } from "./Adapters";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  /* eslint-enable no-bitwise */
  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name[0]}`,
  };
}

interface AvatarProps {
  url?: string;
  username?: string;
  size?: number;
}

export const UserAvatar = ({ url, username, size }: AvatarProps) => {
  const sx = {
    width: size || 50,
    height: size || 50,
    // boxShadow: "1px 1px 10px #bcb6b6",
    // border: "1px solid white",
    // borderRadius: "5px",
  }

  return (
    <>
      {
        url !== undefined
          ? <Avatar src={url} sx={sx} />
          : username !== undefined
            ? <Avatar {...stringAvatar(username)} sx={sx} />
            : <Avatar sx={sx} />
      }
    </>
  )
}
export const NoAvatar = (props: { size?: number }) => <UserAvatar username="?" size={props.size} />
