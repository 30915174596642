import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useSubscription } from "urql";

import { 
  Game,
  GameSideEnum,
  GameStatusEnum,
  GamesMovesProcessedSubscription,
} from "../api/gql/graphql";
import { gameCancel } from "../api/core/mutations/games.cancel";
import { gameJoin } from "../api/core/mutations/games.join";

import { useSnackbarContext } from "./SnackbarContext";
import { gameResign } from "../api/core/mutations/games.resign";
import { useUserContext } from "./UserContext";
import { gameMove } from "../api/core/mutations/games.move";
import { getGame } from "../api/core/queries/games.get";
import { gamesMovesProcessed } from "../api/core/subscriptions/games.moveProcessed";

interface GameContextProps {
  game: Game | undefined

  join: () => void
  cancel: () => void
  resign: () => void

  isStarted: boolean
  isActive: boolean
  isAuthor: boolean

  board: string
  current: GameSideEnum
  player: GameSideEnum

  orientation: GameSideEnum
  flip: () => void

  move: (move: string) => boolean
  lastMove: string[]
};

export const GameContext = React.createContext<GameContextProps>({} as GameContextProps)

export const useGameContext = () => useContext(GameContext)

interface Props {
  value: Game;
  children?: React.ReactNode;
}

const GameProvider: React.FC<Props> = ({ value, children }) => {
  const navigate = useNavigate();
  const snackbar = useSnackbarContext();
  const user = useUserContext();

  const [game, setGame] = useState<Game>(value)
  const [refreshResponse] = useQuery({
    query: getGame,
    pause: true,
    variables: {
      id: game.id,
    }
  })
  useEffect(() => {
    if (refreshResponse.data?.games?.get) setGame(refreshResponse.data.games.get as Game)
  }, [refreshResponse])

  const gameSubscriptionHandle = (messages: any, response: GamesMovesProcessedSubscription) => {
    if (response.gamesMovesProcessed) {
      setGame(response.gamesMovesProcessed as Game)
    }
  }

  // const [gameSubscription] = 
  useSubscription({
    query: gamesMovesProcessed,
    variables: {
      id: game.id,
    },
  }, gameSubscriptionHandle)

  const [joinResponse, joinExec] = useMutation(gameJoin);
  const join = () => {
    joinExec({ id: game.id }).then(result => {
      if (result.error) {
        snackbar.error("Can't join to game.")
        return
      }
      setGame(joinResponse.data?.games?.join as Game)
      snackbar.success("Game started, enjoy.")
    })
  }

  const [, cancelExec] = useMutation(gameCancel);
  const cancel = () => {
    cancelExec({ id: game.id }).then(result => {
      if (result.error) {
        snackbar.error("Can't cancel a game.")
        return
      }
      navigate(-1)
      snackbar.success("Game canceled.")
    })
  }

  const [, resignExec] = useMutation(gameResign);
  const resign = () => {
    resignExec({ id: game.id }).then(result => {
      if (result.error) {
        snackbar.error("Can't resign from a game.")
        return
      }
      navigate(-1)
      snackbar.success("Game finished.")
    })
  }

  const [board, setBoard] = useState(game.progress.board)
  useEffect(() => {
    if (game.progress.board) setBoard(game.progress.board)
  }, [game.progress.board])

  const [current, setCurrent] = useState<GameSideEnum>(game.progress.current)
  useEffect(() => {
    if (game.progress.current) setCurrent(game.progress.current)
  }, [game.progress.current])

  const [orientation, setOrientation] = useState<GameSideEnum>(current)
  useEffect(() => {
    if (game.author.id === game.players.white?.id) setOrientation(GameSideEnum.White)
    if (game.author.id === game.players.black?.id) setOrientation(GameSideEnum.Black)
  }, [game.author])

  const flip = () => {
    setOrientation(
      orientation === GameSideEnum.White ? GameSideEnum.Black : GameSideEnum.White
    )
  }

  const [player, setPlayer] = useState<GameSideEnum>(GameSideEnum.White)
  useEffect(() => {
    if (game.players.white?.id === user.id) setPlayer(GameSideEnum.White)
    if (game.players.black?.id === user.id) setPlayer(GameSideEnum.Black)
  }, [game.players])

  const [, moveExec] = useMutation(gameMove)
  const move = (move: string): boolean => {
    moveExec({
      id: game.id,
      move: move,
    }).then(result => {
      if (result.error) {
        snackbar.error("Move failed")
        return false
      }
    })
    return true
  }

  return (
    <GameContext.Provider
      value={{
        "game": game,
        "join": join,
        "cancel": cancel,
        "resign": resign,
        "board": board,
        "current": current,
        "player": player,
        "orientation": orientation,
        "flip": flip,
        "move": move,
        "lastMove": game.progress.moves.length > 0
          ? [game.progress.moves.slice(-1)[0].slice(0, 2), game.progress.moves.slice(-1)[0].slice(2, 4)]
          : [],
        "isAuthor": game.author.id === user.id,
        "isStarted": game.status === GameStatusEnum.Active,
        "isActive": [
          GameStatusEnum.Active,
          GameStatusEnum.Waiting,
          GameStatusEnum.New,
        ].includes(game.status),
      }}
    >
      {children}
    </GameContext.Provider>
  )
}
export default GameProvider
