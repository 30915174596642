import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import TagManager from 'react-gtm-module';

import { useIsTelegramWebAppReady } from '../contexts/telegram-webapp';
import { useUserContext } from '../contexts/UserContext';

import { Stack } from './Adapters';
import { Header } from './AppBar/AppBar';
import { Loader } from './Loaders';

import Leaders from '../routes/Leaders';
import Donate from '../routes/Donate';
import Error from '../routes/Error';
import User from '../routes/User';
import UserProfile from '../routes/UserProfile';
import UserGames from '../routes/UserGames';

import Auth from '../routes/Auth';
import AuthSignIn from './Auth/SignIn';
import AuthSignUp from './Auth/SignUp';

import Account from '../routes/Account';
import AccountView from '../routes/AccountView';
import AccountGames from '../routes/AccountGames';

import MyGames from '../routes/MyGames';
import GameScreen from '../routes/Game';
import GamesCategory from '../routes/GameCategory';

import { GameCreateMain, GameCreateTab } from '../routes/GameCreate';
import { GameChangeTypeForm } from "./GameCreate/ChangeTypeForm";
import { GameChangeBoardForm } from "./GameCreate/ChangeBoardForm";
import { GameChangePlayerForm } from "./GameCreate/ChangePlayerForm";

const tagManagerArgs = {
  gtmId: "GTM-MSNLLRJ",
};

const Main = () => {
  const user = useUserContext()
  const isTelegramWebAppReady = useIsTelegramWebAppReady();

  useEffect(() => {
    if (user.id) {
      TagManager.initialize({...tagManagerArgs,
        dataLayer: {
          userId: user.id,
        }
      });
    } else {
      TagManager.initialize(tagManagerArgs);
    }
  }, [user.id])

  return (
    !isTelegramWebAppReady
      ? <Loader />
      : <Stack direction="column">
        <Header />
        <Routes>
          <Route index element={<MyGames />} />
          <Route path="/" element={<MyGames />} />

          {/* Auth */}
          <Route path="/auth" element={<Auth />}>
            <Route path="signin" element={<AuthSignIn />} />
            <Route path="signup" element={<AuthSignUp />} />
          </Route>

          {/* Account */}
          <Route path="/account" element={<Account />}>
            <Route index element={<AccountView />} />
            <Route path="games" element={<AccountGames />} />
          </Route>

          {/* Users */}
          <Route path="/users/:id" element={<User />}>
            <Route index element={<UserProfile />} />
            <Route path="games" element={<UserGames />} />
          </Route>

          {/* Games */}
          <Route path="/games/create" element={<GameCreateMain />}>
            <Route index element={<GameCreateTab />} />
            <Route path="change/type" element={<GameChangeTypeForm />} />
            <Route path="change/board" element={<GameChangeBoardForm />} />
            <Route path="change/player" element={<GameChangePlayerForm />} />
          </Route>
          <Route path="/games/category/:category" element={<GamesCategory />} />
          <Route path="/games/:id" element={<GameScreen />} />

          {/* Others */}
          <Route path="/leaders" element={<Leaders />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Stack>
  );
};
export default Main;
