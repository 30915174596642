import { useNavigate } from "react-router-dom";

import { UserFullFragmentFragment, UserListConnection } from '../api/gql/graphql';

import { Grid, List, ListItem, Stack, Typography } from './Adapters';
import { UserAvatar } from "./Avatars";

const LeaderListItem = (props: {
  user: UserFullFragmentFragment;
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/users/${props.user.id}`)
  }

  return (
    <ListItem
      disablePadding
      onClick={handleClick}
      style={{ paddingBottom: "20px" }}
    >
      <Grid container xs alignItems="center" justifyContent="space-between" sx={{paddingBottom: "15px"}}>
        <Grid xs={2}>
          <UserAvatar url={props.user.avatar?.default} username={props.user.username} />
        </Grid>
        <Grid xs={7}>
          <Stack direction="column" spacing={1}>
            <Typography>{props.user.displayName}</Typography>
            <Stack direction="row" spacing={1}>
              <Typography className="stats is-win">{props.user.games?.stats.win}</Typography>
              <Typography>/</Typography>
              <Typography className="stats is-draw">{props.user.games?.stats.draw}</Typography>
              <Typography>/</Typography>
              <Typography className="stats is-lose">{props.user.games?.stats.lose}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={2} textAlign="end">
          <Typography>{props.user.ratings?.current}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const LeaderList = (props: {
  data: UserListConnection;
}) => {
  return (
    <List>
      {
        props.data?.edges && props.data.edges.map(
          (e, i) => <LeaderListItem user={e.node as UserFullFragmentFragment} key={`leader-${i}`} />
        )
      }
    </List >
  )
};
export default LeaderList;
