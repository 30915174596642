import { useNavigate } from "react-router";
import { useCallback, useState } from "react";
import { useQuery } from "urql";
import debounce from 'lodash.debounce'

import { userSearchGamePlayers } from "../../api/core/queries/users.search";
import { UserFullFragmentFragment, UserListConnection } from "../../api/gql/graphql";


import { useGameCreateFormContext } from "./FormContext";
import { useUserContext } from "../../contexts/UserContext";
import { Stack, TextField } from "../Adapters";
import { ContentLayout } from "../Layouts";
import UserList from "../UserList";
import { Loader } from "../Loaders";
import { Error } from "../Errors";

export const GameChangePlayerForm = () => {
  const navigate = useNavigate()
  const form = useGameCreateFormContext()
  const user = useUserContext()

  const [search, setSearch] = useState("")

  const [result] = useQuery({
    query: userSearchGamePlayers,
    variables: {
      username: search,
      excludeUsers: [user.id],
    },
    pause: search === "" ? true : false,
  })
  const { data, fetching, error } = result

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearch(event.target.value);
  };

  const onChangeDebounced = useCallback(
    debounce(onChange, 500), []
  )

  const onSelect = (user: UserFullFragmentFragment) => {
    form.setOpponent(user)
    navigate(-1)
  }

  return (
    <ContentLayout>
      <Stack direction="column" spacing={5}>
        <TextField
          fullWidth
          variant="outlined"
          onChange={onChangeDebounced}
          placeholder="Search"
        />

        {fetching && <Loader />}
        {error && <Error message={error.message} />}

        {data?.users?.search &&
          <UserList data={data.users.search as UserListConnection} onClick={onSelect} />}
      </Stack>
    </ContentLayout>
  )
}
