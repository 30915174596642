import { graphql } from "../../gql";

export const gameCancel = graphql(/* GraphQL */ `
  mutation gameCancel(
    $id: ID!

    $skipGameAuthor: Boolean = true
    $skipGameChallenge: Boolean = true
    $skipGameDrawOffer: Boolean = true
    $skipGamePlayers: Boolean = true
    $skipGameProgress: Boolean = true
    $skipGameResult: Boolean = true
    $skipGamePlayerRatings: Boolean = true
    $skipGameTiming: Boolean = true
  ) {
    games {
      cancel(gameID: $id) {
        ...GameFullFragment
      }
    }
  }
`);
