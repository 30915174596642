import { useParams } from "react-router";
import UserRecentGames from "../components/UserRecentGames";

const UserGames = () => {
  const { id: userId } = useParams();

  return (
    <UserRecentGames
      userId={userId || ""} // TODO
      onPage={10}
    />
  )
}
export default UserGames;
