import { CombinedError, Operation } from "urql"

export const retryExchangeOpts = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryWith: (err: CombinedError, operation: Operation) => {
    if (operation.kind === "query") {
      return err && err.networkError ? operation : null
    }
  },
}
