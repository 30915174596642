import { combineComponents } from '../utils/combine';

import AppBarProvider from '../components/AppBar/AppBarContext';
import AuthProvider from './AuthContext';
import SnackbarProvider from './SnackbarContext';
import UserProvider from './UserContext';
import ThemeProvider from '../theme/ThemeContext';

const providers = [
  SnackbarProvider,
  AuthProvider,
  UserProvider,
  AppBarProvider,
  ThemeProvider,
]
export const AppContextProvider = combineComponents(...providers);
