import { graphql } from "../../gql";

export const getLeaders = graphql(/* GraphQL */ `
  query getLeaders(
    $skipPagination: Boolean = false
    $page: PageInput! = { after: 10 }

    $skipUserAvatars: Boolean = false
    $skipUserAvatarDefault: Boolean = false
    $skipUserAvatarOriginal: Boolean = true
    $skipUserRatings: Boolean = true
    $skipUserGamesStats: Boolean = true
  ) {
    users {
      leaders(
        input: {
          page: $page
        }
      ) {
        ...UserListConnectionFragment
      }
    }
  }
`);
