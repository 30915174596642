import { CircularProgress } from "./Adapters";

export const Loader = () => {
	return (
		<div style={{
			position: "fixed",
			top: "50%",
			left: "50%",
			margin: "-50px",
			width: "100px",
			height: "100px",
			textAlign: "center",
		}}>
			<CircularProgress/>
		</div>
	)
};
