import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { GameSideEnum } from "../../api/gql/graphql";
import { useUserContext } from "../../contexts/UserContext";
import { useGameCreateFormContext } from "./FormContext";
import { MappingGameBoardTypeToName, MappingGameTypeToName } from "../../types/mappings";

import { Box, Button, Stack, Switch, ToggleButton, ToggleButtonGroup, Typography } from "../Adapters";
import { IconGameDaily, IconDice, IconArrowRight } from "../Icons";
import { NoAvatar, UserAvatar } from "../Avatars";


export const GameChangeTypeButton = () => {
  const form = useGameCreateFormContext();

  useEffect(() => {}, [form.data.gameType, form.data.timing])

  return (
    <Button
      variant="outlined"
      fullWidth
      startIcon={<IconGameDaily />}
      endIcon={<p style={{ fontSize: "0.9rem" }}>{form.data.timing.title}</p>}
      sx={{
        justifyContent: "space-between",
        textTransform: "none",
        padding: "15px 25px",
      }}
      component={Link} to="change/type"
    >
      {MappingGameTypeToName.keyOf(form.data.gameType)}
    </Button>
  )
}

export const GameBoardTypeButton = () => {
  const form = useGameCreateFormContext()

  useEffect(() => {}, [form.data.boardType])

  return (
    <Button
      variant="outlined"
      sx={{
        justifyContent: "space-between",
        textTransform: "none",
        padding: "15px 25px",
      }}
      component={Link} to="change/board"
    >
      {MappingGameBoardTypeToName.keyOf(form.data.boardType)}
    </Button>
  )
}

export const GameRatedSwitch = () => {
  const form = useGameCreateFormContext()

  return (
    <Switch
      checked={form.data.isRated}
      onChange={() => {
        form.setData({
          ...form.data,
          isRated: !form.data.isRated,
        })
      }}
    />
  )
}

export const GamePlayerSelect = () => {
  const navigate = useNavigate()
  const user = useUserContext()
  const form = useGameCreateFormContext()

  const size = 30;

  return (
    <Box onClick={() => navigate("change/player")}>
      <Stack direction="row" spacing={5} justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <UserAvatar url={user.avatar} username={user.username} size={size} />
          <Typography>vs</Typography>
          {
            form.opponent !== undefined
              ? <UserAvatar url={form.opponent.avatar?.default} username={form.opponent.username} size={size} />
              : <NoAvatar size={size} />
          }

        </Stack>
        <IconArrowRight />
      </Stack>
    </Box>
  )
}

export const GameSideSelect = () => {
  const form = useGameCreateFormContext()
  const [alignment, setAlignment] = React.useState<string | null>('random');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null,
  ) => {
    setAlignment(value);

    switch (value) {
      case "white":
        form.setData({ ...form.data, side: GameSideEnum.White })
        break;
      case "black":
        form.setData({ ...form.data, side: GameSideEnum.Black })
        break;
      default:
        form.setData({ ...form.data, side: undefined })
        break;
    }
  }

  const sx = {
    width: "50px",
  }

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="white" sx={sx}>WH</ToggleButton>
      <ToggleButton value="random" sx={sx}><IconDice /></ToggleButton>
      <ToggleButton value="black" sx={sx}>BL</ToggleButton>
    </ToggleButtonGroup>
  )
}
