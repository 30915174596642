import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import { AuthSignUpPayloadInput } from '../api/gql/graphql';
import { authSignIn } from '../api/core/mutations/auth.signin';
import { authSignUp } from '../api/core/mutations/auth.signup';

import { useTelegramWebApp } from './telegram-webapp';
import { ErrorCodes } from '../types/errors';
import { AuthToken, setToken } from '../data/services/token';

import { Loader } from '../components/Loaders';

interface AuthContextProps {
  isAuthenticated: boolean;
  fetching: boolean;
  signIn: () => void;
  signUp: (overrides: AuthSignUpPayloadInput) => void;
}

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

export const useAuthContext = () => useContext(AuthContext);

interface Props {
  children?: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const telegramWebApp = useTelegramWebApp()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [fetching, setFetching] = useState(false)

  const [, signInExec] = useMutation(authSignIn)
  const signIn = () => {
    setFetching(true);

    signInExec({
      initData: process.env.REACT_APP_TELEGRAM_INIT_DATA || telegramWebApp.initData,
    }).then(result => {
      if (result.error) {
        if (result.error.graphQLErrors.some(
          e => e.extensions?.code === ErrorCodes.NotFound.toString())
        ) {
          navigate("/auth/signup")
        }
        return
      }

      let accessToken = result.data?.auth?.loginByProvider.tokens.access;
      let refreshToken = result.data?.auth?.loginByProvider.tokens.refresh;

      if (accessToken && refreshToken) {
        setToken(AuthToken.Access, accessToken);
        setToken(AuthToken.Refresh, refreshToken);
      }

      setIsAuthenticated(true);
    })
    setFetching(false);
  }

  const [, signUpExec] = useMutation(authSignUp)
  const signUp = (overrides: AuthSignUpPayloadInput) => {
    setFetching(true);

    signUpExec({
      initData: process.env.REACT_APP_TELEGRAM_INIT_DATA || telegramWebApp.initData,
      signUpPaylod: {
        ...overrides,
        avatar: telegramWebApp.initDataUnsafe.user?.photo_url,
      },
    }).then(result => {
      if (result.error) {
        console.log(result.error) // TODO
        return
      }

      let accessToken = result.data?.auth?.loginByProvider.tokens.access;
      let refreshToken = result.data?.auth?.loginByProvider.tokens.refresh;

      if (accessToken && refreshToken) {
        setToken(AuthToken.Access, accessToken);
        setToken(AuthToken.Refresh, refreshToken);
      }

      setIsAuthenticated(true);
      navigate("/")
    })
    setFetching(false);
  }

  return (
    <AuthContext.Provider value={{
      "isAuthenticated": isAuthenticated,
      "fetching": fetching,
      "signIn": signIn,
      "signUp": signUp,
    }}>
      {fetching ? <Loader /> : children}
    </AuthContext.Provider>
  )
}
export default AuthProvider
