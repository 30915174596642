import { graphql } from "../../gql";

export const getAccountMe = graphql(/* GraphQL */ `
  query getAccountMe(
    $skipUserAvatars: Boolean = false
    $skipUserAvatarDefault: Boolean = false
    $skipUserAvatarOriginal: Boolean = true
    $skipUserRatings: Boolean = true
    $skipUserGamesStats: Boolean = true
  ) {
    account {
      me {
        ...UserFullFragment
      }
    }
  }
`);
