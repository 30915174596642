import ReactDOM from 'react-dom/client';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { CacheProvider } from '@emotion/react';
import createCache from "@emotion/cache";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './index.css';
import './Icons';
import App from './App';

Sentry.init({
  dsn: "https://b74b3988359941bc9aaa3323f8535efe@o4503993381224448.ingest.sentry.io/4504182079291392",
  integrations: [
    new BrowserTracing(),
  ],
  tracesSampleRate: 0.1,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <CacheProvider value={createCache({ key: "styles", stylisPlugins: [] })}>
    <App />
  </CacheProvider>
);
