import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as GraphqlProvider } from 'urql';

import graphqlClient from './api/clients/graphql';

import { withTelegramWebApp } from './contexts/telegram-webapp';
import { AppContextProvider } from './contexts/AppContext';

import Main from "./components/Main";
import './App.css';

async function validateHash() {
  return true; // TODO
}

const App = () => (
  <HelmetProvider>
    <Helmet>
      <script src="https://telegram.org/js/telegram-web-app.js"></script>
    </Helmet>
    <div id="app">
      <GraphqlProvider value={graphqlClient}>
        <BrowserRouter basename="/">
          <AppContextProvider>
            <Main />
          </AppContextProvider>
        </BrowserRouter>
      </GraphqlProvider>
    </div>
  </HelmetProvider >
);

export default withTelegramWebApp(App, {
  validateHash
});
