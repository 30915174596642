import { useUserContext } from "../contexts/UserContext";

import UserRecentGames from "../components/UserRecentGames";

const AccountGames = () => {
  const user = useUserContext()

  return (
    <UserRecentGames
      userId={user.id}
      onPage={10}
    />
  )
}
export default AccountGames;
