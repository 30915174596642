import { useQuery } from 'urql';

import { UserListConnection } from '../api/gql/graphql';
import { getLeaders } from '../api/core/queries/users.leaders';

import LeaderList from '../components/LeaderList';
import { Loader } from '../components/Loaders';
import { Error } from '../components/Errors';
import { ContentLayout } from '../components/Layouts';

const Leaders = () => {
  const [result] = useQuery({
    query: getLeaders,
    variables: {
      skipUserRatings: false,
      skipUserGamesStats: false,
    },
  })
  const { data, fetching, error } = result;

  return (
    <>
      {fetching && <Loader />}

      {error && <Error info={error.message} />}

      {
        data && 
        <ContentLayout>
          <LeaderList data={data.users?.leaders as UserListConnection} />
        </ContentLayout>
      }
    </>
  )
};
export default Leaders;