import { useEffect, useState } from "react";

import { GameBoardTypeEnum } from "../../api/gql/graphql";

import { useGameCreateFormContext } from "./FormContext";
import { Button, Stack } from "../Adapters";
import { ContentLayout } from "../Layouts";
import { IconChessBoard } from "../Icons";


export const GameChangeBoardForm = () => {
  const form = useGameCreateFormContext();

  const [state, setState] = useState(GameBoardTypeEnum.Standart);

  useEffect(() => {
    setState(form.data.boardType);
  }, [form.data.boardType])

  const sx = {
    justifyContent: "space-between",
    padding: "15px 25px",
  };

  const buttonVariant = (boardType: GameBoardTypeEnum) => {
    return state === boardType ? "contained" : "outlined";
  };

  const handleClick = (boardType: GameBoardTypeEnum) => {
    form.setData({ ...form.data, boardType: boardType });
  };

  return (
    <ContentLayout>
      <Stack direction="column" spacing={3}>
        <Button
          fullWidth
          sx={sx}
          startIcon={<IconChessBoard />}
          endIcon={<div />}
          variant={buttonVariant(GameBoardTypeEnum.Standart)}
          onClick={() => { handleClick(GameBoardTypeEnum.Standart); }}
        >
          Standart
        </Button>
        <Button
          fullWidth
          sx={sx}
          startIcon={<IconChessBoard />}
          endIcon={<div />}
          variant={buttonVariant(GameBoardTypeEnum.Chess960)}
          onClick={() => { handleClick(GameBoardTypeEnum.Chess960); }}
        >
          Chess960
        </Button>
      </Stack>
    </ContentLayout>
  );
};
