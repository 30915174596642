import { useNavigate } from "react-router";
import { GameTiming, GameTypeEnum } from "../../api/gql/graphql";

import { Button, Stack, Typography } from "../Adapters";
import { ContentLayout } from "../Layouts";
import { useGameCreateFormContext } from "./FormContext";

type TimingPreset = {
  title: string;
  timing: GameTiming;
  gameType: GameTypeEnum;
}

type TimingPresetGrid = {
  bullet: TimingPreset[],
  blitz: TimingPreset[],
  rapid: TimingPreset[],
  daily: TimingPreset[],
}

const TimingButtons: TimingPresetGrid = {
  bullet: [
    { title: "1 min", gameType: GameTypeEnum.Bullet, timing: { initial: 1, bonus: 0 } },
    { title: "1 | 1", gameType: GameTypeEnum.Bullet, timing: { initial: 1, bonus: 1 } },
    { title: "2 | 1", gameType: GameTypeEnum.Bullet, timing: { initial: 2, bonus: 0 } },
  ],
  blitz: [
    { title: "3 min", gameType: GameTypeEnum.Blitz, timing: { initial: 3, bonus: 0 } },
    { title: "3 | 2", gameType: GameTypeEnum.Blitz, timing: { initial: 3, bonus: 2 } },
    { title: "5 min", gameType: GameTypeEnum.Blitz, timing: { initial: 2, bonus: 0 } },
  ],
  rapid: [
    { title: "10 min", gameType: GameTypeEnum.Rapid, timing: { initial: 10, bonus: 0 } },
    { title: "15 | 10", gameType: GameTypeEnum.Rapid, timing: { initial: 15, bonus: 10 } },
    { title: "30 min", gameType: GameTypeEnum.Rapid, timing: { initial: 30, bonus: 0 } },
  ],
  daily: [
    { title: "1 day", gameType: GameTypeEnum.Daily, timing: { initial: 1440, bonus: 0 } },
    { title: "3 day", gameType: GameTypeEnum.Daily, timing: { initial: 4320, bonus: 0 } },
    { title: "7 day", gameType: GameTypeEnum.Daily, timing: { initial: 7, bonus: 0 } },
  ],
}

export const GameChangeTypeForm = () => {
  const navigate = useNavigate();
  const form = useGameCreateFormContext();

  const onButtonClick = (data: TimingPreset) => {
    form.setData({
      ...form.data,
      gameType: data.gameType,
      timing: {
        title: data.title,
        initial: data.timing.initial,
        bonus: data.timing.bonus,
      }
    })
    navigate(-1)
  }

  const button = (data: TimingPreset) => {
    return <Button
      fullWidth
      variant={
        form.data.timing.title === data.title ? "contained" : "outlined"
      }
      onClick={() => onButtonClick(data)}
      sx={{
        height: "40px",
        textTransform: "none",
      }}
    >
      {data.title}
    </Button>
  }

  return (
    <ContentLayout>
      <Stack direction="column" spacing={3}>

        {/* Bullet */}
        <Stack>
          <Typography>Bullet</Typography>
        </Stack>
        <Stack direction="row" spacing={2} flex={1}>
          {button(TimingButtons.bullet[0])}
          {button(TimingButtons.bullet[1])}
          {button(TimingButtons.bullet[2])}
        </Stack>

        {/* Blits */}
        <Stack>
          <Typography>Blitz</Typography>
        </Stack>
        <Stack direction="row" spacing={2} flex={1}>
          {button(TimingButtons.blitz[0])}
          {button(TimingButtons.blitz[1])}
          {button(TimingButtons.blitz[2])}
        </Stack>

        {/* Rapid */}
        <Stack>
          <Typography>Rapid</Typography>
        </Stack>
        <Stack direction="row" spacing={2} flex={1}>
          {button(TimingButtons.rapid[0])}
          {button(TimingButtons.rapid[1])}
          {button(TimingButtons.rapid[2])}
        </Stack>

        {/* Daily */}
        <Stack>
          <Typography>Daily</Typography>
        </Stack>
        <Stack direction="row" spacing={2} flex={1}>
          {button(TimingButtons.daily[0])}
          {button(TimingButtons.daily[1])}
          {button(TimingButtons.daily[2])}
        </Stack>
      </Stack>
    </ContentLayout>
  );
};
