
import { Button, Divider, Stack, Typography } from "../Adapters";

import { useGameCreateFormContext } from "./FormContext";
import {
  GameChangeTypeButton,
  GameBoardTypeButton,
  GameRatedSwitch,
  GameSideSelect,
  GamePlayerSelect,
} from "./Controls";

export const GameCreateForm = () => {
  const form = useGameCreateFormContext()

  return (
    <Stack direction="column" spacing={3}>
      <GameChangeTypeButton />
      <Divider />
      <GamePlayerSelect />
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Board</Typography>
        <GameBoardTypeButton />
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Rated Game</Typography>
        <GameRatedSwitch />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>I play as</Typography>
        <GameSideSelect />
      </Stack>
      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={form.submit}
      >
        Play
      </Button>
    </Stack>
  )
};
