import { graphql } from "../../gql";

export const GameFullFragment = graphql(/* GraphQL */ `
  fragment GameFullFragment on Game {
    id
    status
    type
    boardType
    isRated
    author @skip(if: $skipGameAuthor) {
      ...GamePlayerFragment
    }
    challenge @skip(if: $skipGameChallenge) {
      status
      user {
        id
      }
    }
    drawOffer @skip(if: $skipGameDrawOffer) {
      side
      status
    }
    players @skip(if: $skipGamePlayers) {
      white {
        ...GamePlayerFragment
      }
      black {
        ...GamePlayerFragment
      }
    }
    timing @skip(if: $skipGameTiming) {
      initial
      bonus
    }
    progress @skip(if: $skipGameProgress) {
      board
      current
      moves
    }
    result @skip(if: $skipGameResult) {
      outcome
      method
    }
    createdAt
    joinedAt
    endedAt
  }
`);

export const GameListConnectionFragment = graphql(/* GraphQL */ `
  fragment GameListConnectionFragment on GameListConnection {
    totalCount @skip(if: $skipPagination)
    ...ListConnectionFragment @skip(if: $skipPagination)
    edges {
      node {
        ...GameFullFragment
      }
    }
  }
`);
