import { graphql } from "../../gql";

export const userSearchGamePlayers = graphql(/* GraphQL */ `
  query searchUser(
    $skipPagination: Boolean = false
    $page: PageInput = { after: 10 }

    $username: String!
    $excludeUsers: [ID!]

    $skipUserAvatars: Boolean = false
    $skipUserAvatarDefault: Boolean = false
    $skipUserAvatarOriginal: Boolean = true
    $skipUserRatings: Boolean = false
    $skipUserGamesStats: Boolean = true
  ) {
    users {
      search(
        input: {
          page: $page
          username: {
            filter: FILTER_STRING_ILIKE
            value: $username
          }
          excludeUsers: $excludeUsers
        }
      )  {
        ...UserListConnectionFragment
      }
    }
  }
`);

export const userSearchSignup = graphql(/* GraphQL */ `
  query userSearchUsernameOnly(
    $username: String!
  ){
    users {
      search(
        input: {
          username: {
            filter: FILTER_STRING_EXACT
            value: $username
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`);
