// Others
import {
    Avatar as AvatarMui,
    Divider as DividerMui,
    Badge as BadgeMui,
    CircularProgress as CircularProgressMui,
    Typography as TypographyMui,
    Snackbar as SnackbarMui,
    Alert as AlertMui,
    Button as ButtonMui,
    IconButton as IconButtonMui,
    Chip as ChipMui,
    Switch as SwitchMui,
    Tab as TabMui,
    List as ListMui,
    ListItem as ListItemMui,
    ListItemAvatar as ListItemAvatarMui,
    ListItemText as ListItemTextMui,
    ListItemButton as ListItemButtonMui,
    Container as ContainerMui,
    Box as BoxMui,
    Unstable_Grid2 as GridMui,
    AppBar as AppBarMui,
    Toolbar as ToolBarMui,
    Stack as StackMui,
    ToggleButtonGroup as ToggleButtonGroupMui,
    ToggleButton as ToggleButtonMui,
    InputBase as InputBaseMui,
    Dialog as DialogMui,
    DialogTitle as DialogTitleMui,
    DialogContent as DialogContentMui,
    TextField as TextFieldMui,
    Drawer as DrawerMui,
} from '@mui/material';

import {
    TabContext as TabContextMui,
    TabList as TabListMui,
    TabPanel as TabPanelMui,
} from '@mui/lab';

export const Avatar = AvatarMui;
export const Divider = DividerMui;
export const Badge = BadgeMui;
export const CircularProgress = CircularProgressMui;
export const Typography = TypographyMui;

// Dialogs
export const Dialog = DialogMui;
export const DialogTitle = DialogTitleMui;
export const DialogContent = DialogContentMui;

// Snackbars
export const Snackbar = SnackbarMui;
export const Alert = AlertMui

// Controls
export const Button = ButtonMui;
export const IconButton = IconButtonMui;
export const Chip = ChipMui;
export const Switch = SwitchMui
export const ToggleButtonGroup = ToggleButtonGroupMui
export const ToggleButton = ToggleButtonMui
export const TextField = TextFieldMui
export const InputBase = InputBaseMui

// Tabs
export const TabContext = TabContextMui;
export const TabList = TabListMui;
export const TabPanel = TabPanelMui;
export const Tab = TabMui;

// Lists
export const List = ListMui;
export const ListItem = ListItemMui;
export const ListItemAvatar = ListItemAvatarMui;
export const ListItemText = ListItemTextMui;
export const ListItemButton = ListItemButtonMui;

// Layouts
export const Container = ContainerMui;
export const Box = BoxMui;
export const Grid = GridMui;
export const AppBar = AppBarMui;
export const ToolBar = ToolBarMui;
export const Stack = StackMui;
export const Drawer = DrawerMui;
