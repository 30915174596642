import {
  createClient,
  fetchExchange,
  errorExchange,
  subscriptionExchange,
} from 'urql';

import { createClient as createWSClient } from 'graphql-ws';

import { devtoolsExchange } from '@urql/devtools';
import { persistedExchange } from '@urql/exchange-persisted';
import { refocusExchange } from '@urql/exchange-refocus';

import { authExchangeEx } from '../exchanges/auth.exchange';

import { populateExchange } from '@urql/exchange-populate';
import { populateExchangeOpts } from '../exchanges/populate.exchange';

// import { cacheExchange } from '@urql/exchange-graphcache';

import { retryExchange } from '@urql/exchange-retry';
import { retryExchangeOpts } from '../exchanges/retry.exchange';

import { errorExchangeOpts } from '../exchanges/error.exchange';

const wsClient = createWSClient({
  url: process.env.REACT_APP_GRAPHQL_WS_URL || "ws://localhost:8080/graphql",
  lazy: true,
  keepAlive: 10_000,
});

const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_HTTP_URL || "http://localhost:8080/graphql",
  fetchOptions: {},
  exchanges: [
    devtoolsExchange,
    errorExchange(errorExchangeOpts),
    refocusExchange(),
    authExchangeEx,
    // cacheExchange(),
    persistedExchange(),
    populateExchange(populateExchangeOpts),
    retryExchange(retryExchangeOpts),
    subscriptionExchange({
      // enableAllOperations: true,
      forwardSubscription(request) {
        const input = { ...request, query: request.query || '' };
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);
            return { unsubscribe };
          },
        };
      },
    }),
    fetchExchange,
  ],
});

export default client;
