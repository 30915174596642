import Immutable from "immutable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GameTypeEnum } from "../api/gql/graphql";

const sx = {
  fontSize: "20px",
  color: "inherit",
}

export const Icon = FontAwesomeIcon

export const IconHome = () => <FontAwesomeIcon icon="home" style={sx} />
export const IconMenu = () => <FontAwesomeIcon icon="bars" style={sx} />
export const IconQuestion = () => <FontAwesomeIcon icon="circle-question" style={sx} />
export const IconClose = () => <FontAwesomeIcon icon="close" style={sx} />
export const IconBack = () => <FontAwesomeIcon icon="angle-left" style={sx} />
export const IconSearch = () => <FontAwesomeIcon icon="search" style={sx} />
export const IconUser = () => <FontAwesomeIcon icon="user" style={sx} />
export const IconUsers = () => <FontAwesomeIcon icon="users" style={sx} />

// Arrows
export const IconArrowRight = () => <FontAwesomeIcon icon="angle-right" style={sx} />
export const IconArrowLeft = () => <FontAwesomeIcon icon="angle-left" style={sx} />
export const IconArrowDown = () => <FontAwesomeIcon icon="angle-down" style={sx} />

// GameTypes
export const IconGameDaily = () => <FontAwesomeIcon icon="sun" color="orange" style={sx} />
export const IconGameRapid = () => <FontAwesomeIcon icon="stopwatch" color="green" style={sx} />
export const IconGameBlitz = () => <FontAwesomeIcon icon="bolt" color="orange" style={sx} />

export const IconMappingGameType = (type: GameTypeEnum) => {
  switch (type) {
    case GameTypeEnum.Bullet:
    case GameTypeEnum.Blitz:
      return <IconGameBlitz />
    case GameTypeEnum.Rapid:
      return <IconGameRapid />
    case GameTypeEnum.Daily:
      return <IconGameDaily />
    default:
      return <Icon icon="square" />
  }
}

// Chess
export const IconDice = () => <FontAwesomeIcon icon="dice" style={sx} />
export const IconChessBoard = () => <FontAwesomeIcon icon="chess-board" style={sx} />
export const IconChessKingWhite = () => <FontAwesomeIcon icon="chess-king" style={sx} />
export const IconChessKingBlack = () => <FontAwesomeIcon icon="chess-king" color="black" style={sx} />

export const IconChess = () => <FontAwesomeIcon icon="chess" style={sx} />
export const IconChessKing = () => <FontAwesomeIcon icon="chess-king" style={sx} />
export const IconChessBishop = () => <FontAwesomeIcon icon="chess-bishop" style={sx} />
export const IconChessKnight = () => <FontAwesomeIcon icon="chess-knight" style={sx} />
export const IconChessPawn = () => <FontAwesomeIcon icon="chess-pawn" style={sx} />
export const IconChessQueen = () => <FontAwesomeIcon icon="chess-queen" style={sx} />
export const IconChessRook = () => <FontAwesomeIcon icon="chess-rook" style={sx} />

// Chess
export const IconWin = <FontAwesomeIcon icon="square-check" style={{ ...sx, color: "green" }} />
export const IconLose = <FontAwesomeIcon icon="square-xmark" style={{ ...sx, color: "red" }} />
export const IconDraw = <FontAwesomeIcon icon="square" style={{ ...sx, color: "grey" }} />

export const IconMappingGameOutcome = Immutable.Map<string, JSX.Element>({
  "OutcomeWin": IconWin,
  "OutcomeLose": IconLose,
  "OutcomeDraw": IconDraw,
})
