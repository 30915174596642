import { authExchange } from '@urql/exchange-auth';

import { authRefresh } from '../core/mutations/auth.refresh';
import { ErrorCodes } from '../../types/errors';
import {
  AuthToken,
  getToken,
  setToken,
  clearTokens,
} from '../../data/services/token';

export const authExchangeEx = authExchange(async (utils) => {
  let token = getToken(AuthToken.Access);
  let refreshToken = getToken(AuthToken.Refresh);
  
  return {
    addAuthToOperation(operation) {
      if (!token) return operation;
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${token}`,
      });      
    },

    willAuthError(_operation) {
      return !token;
    },

    didAuthError(error) {
      return error.graphQLErrors.some(e => e.extensions?.code === ErrorCodes.Unauthorized);
    },

    async refreshAuth() {
      if (!refreshToken) {
        return
      }

      const result = await utils.mutate(authRefresh, {
        token: refreshToken!, // TODO
      });  

      if (result.data?.auth?.refresh.tokens) {
        token = result.data.auth.refresh.tokens.access
        refreshToken = result.data.auth.refresh.tokens.refresh
      }

      if (token && refreshToken) {
        setToken(AuthToken.Access, token);
        setToken(AuthToken.Refresh, refreshToken);
      } else {
        clearTokens()
      }
    },
  };
});
