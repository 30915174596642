import { useParams } from "react-router";
import { useQuery } from "urql";

import { Game as GameType } from "../api/gql/graphql";
import { GetGameRequest } from "../api/core/queries/games.get";

import GameProvider from "../contexts/GameContext";


import { Error } from "../components/Errors";
import { Loader } from "../components/Loaders";
import Game from "../components/Game/Game";


type GameParams = {
  id: string;
}

const GameScreen = () => {
  const { id } = useParams<GameParams>()

  const [result] = useQuery(GetGameRequest(id!))
  const { data, fetching, error } = result;

  return (
    <>
      {fetching && <Loader />}
      {error && <Error info={error.message} />}
      {
        data?.games?.get && <GameProvider value={data.games.get as GameType}>
          <Game />
        </GameProvider>
      }
    </>
  )
}
export default GameScreen;
