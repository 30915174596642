import React, { useEffect, useState } from "react";
import { GamePlayerFragmentFragment, GameSideEnum } from "../../api/gql/graphql";

import { useGameContext } from "../../contexts/GameContext";

import { Box, Stack } from "../Adapters";
import { GameBoard } from "./GameBoard";
import { GamePlayerComponent, NoGamePlayer } from "./GamePlayer";
import GameResult from "./Result";

const Game = () => {
  const game = useGameContext()

  const [position, setPosition] = useState({
    top: game.game?.players.black,
    bottom: game.game?.players.white,
  })

  useEffect(() => {
    switch (game.orientation) {
      case GameSideEnum.White:
        setPosition({
          top: game?.game?.players.black,
          bottom: game?.game?.players.white,
        })
        break;
      case GameSideEnum.Black:
        setPosition({
          top: game?.game?.players.white,
          bottom: game?.game?.players.black,
        })
        break;
    }
  }, [game.orientation])

  return (
    <React.Fragment>
      <Stack direction="column">
        <Box className="layout main">
          {
            position.top
              ? <GamePlayerComponent data={position.top as GamePlayerFragmentFragment} />
              : <NoGamePlayer />
          }
        </Box>
        <GameBoard />
        <Box className="layout main">
          {
            position.bottom
              ? <GamePlayerComponent data={position.bottom as GamePlayerFragmentFragment} />
              : <NoGamePlayer />
          }
        </Box>
      </Stack>
      <GameResult />
    </React.Fragment>
  )
}
export default Game;
