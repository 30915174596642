import { useNavigate } from "react-router";

import { useAppBarContext } from "./AppBarContext"

import { AppBar, IconButton, ToolBar, Typography } from "../Adapters"

import { ReactComponent as LogoSvg } from '../../assets/logo.svg';

import MenuButton from "./MenuButton"
import BackButton from "./BackButton"

export const Header = () => {
  const navigate = useNavigate()
  const appBar = useAppBarContext()

  return (
    <AppBar position="relative">
      <ToolBar>
        <IconButton onClick={() => {navigate("/")}}>
          <LogoSvg className="logo svg" />
        </IconButton>
        <Typography
          variant="h5"
          component="h1"
          sx={{
            flexGrow: 1,
            fontWeight: 400,
            letterSpacing: '.15rem',
            paddingLeft: "5px",
          }}
        >
          {appBar.title || "Chess Online"}
        </Typography>
        {appBar.action === "menu" && <MenuButton />}
        {appBar.action === "back" && <BackButton />}
        {appBar.action === "close" && <BackButton />}
      </ToolBar>
    </AppBar>
  )
}
