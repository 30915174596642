import { User, UserFullFragmentFragment, UserListConnection } from "../api/gql/graphql";

import { Grid, List, ListItem, Typography } from "./Adapters";
import { UserAvatar } from "./Avatars";

type UserListItemType = (position: number, user: User) => JSX.Element

type UserListItemClickable = (user: UserFullFragmentFragment) => void;

interface UserListItemProps {
  user: UserFullFragmentFragment;
  onClick?: UserListItemClickable;
}

const UserListItem = ({ user, onClick }: UserListItemProps) => {
  return (
    <ListItem
      disablePadding
      onClick={() => { onClick && onClick(user as UserFullFragmentFragment) }}
      style={{ paddingBottom: "15px" }}
    >

      <Grid container xs alignItems="center" justifyContent="space-between" sx={{ paddingBottom: "15px" }}>
        <Grid xs={2}>
          <UserAvatar url={user.avatar?.default} username={user.username} />
        </Grid>
        <Grid xs={7}>
          <Typography>{user.displayName}</Typography>
        </Grid>
        <Grid xs={2} textAlign="end">
          <Typography>{user.ratings?.current}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const UserList = (props: {
  data: UserListConnection;
  item?: UserListItemType;
  onClick?: UserListItemClickable;
}) => {
  return (
    <List>
      {
        props.data?.edges && props.data.edges.map(
          (e, i) => props.item
            ? props.item(i, e.node)
            : <UserListItem user={e.node as UserFullFragmentFragment} key={e.node.id} onClick={props.onClick} />
        )
      }
    </List >
  )
};
export default UserList;
