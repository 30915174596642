import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { 
  Box, 
  Divider, 
  Drawer, 
  Stack,
  IconButton, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemText,
} from "../Adapters"
import { IconArrowRight, IconChess, IconMenu, IconUsers } from "../Icons"
import { ListItemIcon, Typography } from "@mui/material"
import { UserAvatar } from "../Avatars"
import { useUserContext } from "../../contexts/UserContext"
import { useTelegramWebApp } from "../../contexts/telegram-webapp"

const MenuButton = (props: { open?: boolean }) => {
  const navigate = useNavigate()
  const user = useUserContext()
  const telegram = useTelegramWebApp()

  const [open, setOpen] = useState(props.open || false)

  const onClick = () => {
    setOpen(true)
    telegram.expand()
  }

  const onClose = () => { setOpen(false) }

  const sxItem = {
    padding: "10px 0",
    maxWidth: "250px",
    overflow: "hidden",
  }

  return (
    <React.Fragment>
      <IconButton onClick={onClick}>
        <IconMenu />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Stack flex={1} direction="column" alignItems="end" sx={{ minWidth: "250px" }}>
          <Box sx={{ padding: "10px 15px" }}>
            <IconButton onClick={onClose}>
              <IconArrowRight />
            </IconButton>
          </Box>
          <List sx={{ width: "100%" }}>
            <ListItem disablePadding sx={sxItem}>
              <ListItemButton onClick={() => navigate("account")}>
                <ListItemAvatar sx={{ paddingRight: "15px" }}>
                  <UserAvatar url={user.avatar} username={user.username} />
                </ListItemAvatar>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h5">{user.username}</Typography>
                  <Typography>{user.ratings}</Typography>
                </Stack>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding sx={sxItem}>
              <ListItemButton onClick={() => navigate("games/create")}>
                <ListItemIcon><IconChess /></ListItemIcon>
                <ListItemText primary="New Game" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={sxItem}>
              <ListItemButton onClick={() => navigate("leaders")}>
                <ListItemIcon><IconUsers /></ListItemIcon>
                <ListItemText primary="Leaders" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </List>
        </Stack>
      </Drawer>
    </React.Fragment >
  )
}
export default MenuButton;
