import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import { getAccountMe } from '../api/core/queries/account.me';
import { UserFullFragmentFragment } from '../api/gql/graphql';

import { ErrorCodes } from '../types/errors';
import { useAuthContext } from './AuthContext';

interface ContextProps {
  id: string;
  username: string;
  displayName: string;
  avatar?: string;
  ratings: string;
  createdAt: Date;

  fetching: boolean
  refresh: () => void;
}

export const UserContext = React.createContext<ContextProps>({} as ContextProps);

export const useUserContext = () => useContext(UserContext);

interface Props {
  children?: React.ReactNode;
}

interface StateProps {
  user: UserFullFragmentFragment | null;
  loading: boolean;
}

const UserProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const auth = useAuthContext();

  const [state, setState] = React.useState<StateProps>({
    user: null,
    loading: true,
  })

  const [result, reexecuteQuery] = useQuery({
    query: getAccountMe,
    variables: {
      skipUserRatings: false,
      skipUserGamesStats: true,
    },
    requestPolicy: "network-only",
  })

  const { data, fetching, error } = result;

  useEffect(() => {
    if (fetching) return;

    if (data?.account?.me) {
      setState({
        user: data.account.me as UserFullFragmentFragment,
        loading: false,
      })
    }
  }, [data, fetching, error])

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors.some(e => e.extensions?.code === ErrorCodes.Unauthorized)) {
        navigate("/auth/signin");
        return
      }

      if (error.graphQLErrors.some(e => e.extensions?.code === ErrorCodes.NotFound)) {
        navigate("/auth/signup");
        return
      }
    }
  }, [error]);

  const refresh = () => { reexecuteQuery() }

  useEffect(() => {
    if (auth.isAuthenticated) {
      refresh();
    }
  }, [auth.isAuthenticated]);

  return (
    <UserContext.Provider value={{
      "id": (state.user && state.user.id) || "",
      "username": (state.user && state.user.username) || "",
      "displayName": (state.user && state.user.displayName) || "",
      "avatar": (state.user && state.user.avatar?.default) || "",
      "ratings": `${state?.user?.ratings?.current}`,
      "createdAt": state.user?.createdAt,
      "refresh": refresh,
      "fetching": fetching,
    }}>
      {children}
    </UserContext.Provider>
  )
}
export default UserProvider
