import { graphql } from "../../gql";

export const getUserProfile = graphql(/* GraphQL */ `
  query getUserProfile(
    $id: ID!

    $skipUserAvatars: Boolean = false
    $skipUserAvatarDefault: Boolean = false
    $skipUserAvatarOriginal: Boolean = true
    $skipUserRatings: Boolean = false
    $skipUserGamesStats: Boolean = false
  ) {
    users {
      get(id: $id) {
        ...UserFullFragment
      }
    }
  }
`);
