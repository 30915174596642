import Immutable from 'immutable';

import { GameBoardTypeEnum, GameSideEnum, GameTypeEnum } from '../api/gql/graphql';

export const MappingGameSideToName = Immutable.Map<string, GameSideEnum>({
  "White": GameSideEnum.White,
  "Black": GameSideEnum.Black,
})

export const MappingGameTypeToName = Immutable.Map<string, GameTypeEnum>({
  "Blitz": GameTypeEnum.Blitz,
  "Bullet": GameTypeEnum.Bullet,
  "Rapid": GameTypeEnum.Rapid,
  "Daily": GameTypeEnum.Daily,
})

export const MappingGameBoardTypeToName = Immutable.Map<string, GameBoardTypeEnum>({
  "Standart": GameBoardTypeEnum.Standart,
  "Chess960": GameBoardTypeEnum.Chess960,
})
