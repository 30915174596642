import { graphql } from "../../gql";

export const authSignIn = graphql(/* GraphQL */ `
  mutation authSignIn(
    $initData: MapString! = {}
  ) {
    auth {
      loginByProvider(
        provider: TELEGRAM
        input: {
          payload: {
            initData: $initData
          }
          signUp: false
        }
      ) {
        tokens {
          access
          refresh
        }
      }
    }
  }
`);
