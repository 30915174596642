import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CombinedError, useMutation } from 'urql';

import { useSnackbarContext } from '../../contexts/SnackbarContext';

import { gameCreate } from '../../api/core/mutations/games.create';
import { gameChallenge } from '../../api/core/mutations/games.createChallenge';
import {
  Game,
  GameBoardTypeEnum,
  GameSideEnum,
  GameTypeEnum,
  UserFullFragmentFragment,
  GameTiming,
} from '../../api/gql/graphql';

type FormInput = {
  gameType: GameTypeEnum;
  boardType: GameBoardTypeEnum;
  side?: GameSideEnum | undefined;
  isRated: boolean;
  timing: GameTiming & {
    title: string,
  };
}

interface ContextProps {
  data: FormInput;
  setData: (data: FormInput) => void;

  opponent: UserFullFragmentFragment | undefined;
  setOpponent: (user: UserFullFragmentFragment) => void;

  submit: () => void;
}

export const GameCreateContext = React.createContext<ContextProps>({} as ContextProps);

export const useGameCreateFormContext = () => useContext(GameCreateContext);

interface Props {
  children?: React.ReactNode;
}

const defaultFormInput: FormInput = {
  gameType: GameTypeEnum.Rapid,
  boardType: GameBoardTypeEnum.Standart,
  side: undefined,
  isRated: true,
  timing: {
    title: "1 day",
    initial: 300,
    bonus: 0,
  },
}

const GameCreateFormProvider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<FormInput>({ ...defaultFormInput })
  const [opponent, setOpponent] = useState<UserFullFragmentFragment>()

  const navigate = useNavigate()
  const snackbar = useSnackbarContext();

  const [, createGameExec] = useMutation(gameCreate);
  const [, createChallengeExec] = useMutation(gameChallenge);

  const [game, setGame] = useState<Game | null>(null)
  const [error, setError] = useState<CombinedError | null>(null)

  const createGame = () => {
    createGameExec({
      input: {
        type: data.gameType,
        boardType: data.boardType,
        side: data.side,
        isRated: data.isRated,
      },
      timing: {
        initial: data.timing.initial,
        bonus: data.timing.bonus,
      },
    }).then(result => {
      if (result.error) {
        setError(result.error)
        return
      }
      setGame(result.data?.games?.create as Game)
    })
  }

  const createChallenge = () => {
    createChallengeExec({
      userId: opponent!.id,
      input: {
        type: data.gameType,
        boardType: data.boardType,
        side: data.side,
        isRated: data.isRated,
      },
      timing: {
        initial: data.timing.initial,
        bonus: data.timing.bonus,
      },
    }).then(result => {
      if (result.error) {
        setError(result.error)
        return
      }
      setGame(result.data?.games?.challengeCreate as Game)
    })
  }

  const submit = () => {
    if (opponent) {
      createChallenge()
    } else {
      createGame()
    }
  }

  useEffect(() => {
    if (error) snackbar.error("Oh no, something went wrong ...")
  }, [error])

  useEffect(() => {
    if (game) {
      snackbar.success("Game created, wait another player!")
      navigate(`/games/${game.id}`)
      reset()
    }
  }, [game])

  const reset = () => {
    setGame(null)
    setError(null)
    setData({ ...defaultFormInput })
  }

  return (
    <GameCreateContext.Provider
      value={{
        "data": data,
        "setData": setData,
        "submit": submit,
        "opponent": opponent,
        "setOpponent": setOpponent,
      }}
    >
      {children}
    </GameCreateContext.Provider>
  )
}
export default GameCreateFormProvider
