import { useQuery } from "urql";

import { useFragment } from "../../api/gql";
import { getOpenGames } from "../../api/core/queries/games.open";
import { GameListConnectionFragment } from "../../api/core/fragments/games";

import { useUserContext } from "../../contexts/UserContext";

import { Error } from "../Errors";
import { Loader } from "../Loaders";
import { GameList } from "./GameList";

const GamesOpen = () => {
  const user = useUserContext()

  const [result] = useQuery({
    query: getOpenGames,
    variables: {
      excludePlayers: [user.id],
    },
  })
  const { data, fetching, error } = result;

  const games = useFragment(GameListConnectionFragment, data?.games?.search)

  return (
    <>
      {fetching && <Loader />}
      {error && <Error info={error.message} />}

      {games && <GameList query={games} showAvatar="author" />}
    </>
  )
};
export default GamesOpen;
