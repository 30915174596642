import React, { useContext, useEffect, useState } from 'react';
import { createTheme, ThemeProvider as ThemeProviderMui } from '@mui/material';

import { useTelegramWebApp } from '../contexts/telegram-webapp';
import { getMainTheme } from './main';

export const ThemeContext = React.createContext({});

export const useThemeContext = () => useContext(ThemeContext);

type ColorMode = 'light' | 'dark'

interface Props {
  children?: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const telegram = useTelegramWebApp()

  const [mode, setMode] = useState<ColorMode>('light');

  useEffect(() => {
    if (telegram) {
      setMode(telegram.colorScheme || 'light')
    }
  }, [telegram])
  
  const theme = React.useMemo(
    () => createTheme(getMainTheme(mode)),
    [mode],
  );

  return (
    <ThemeContext.Provider value={{}}>
      <ThemeProviderMui theme={theme}>
        {children}
      </ThemeProviderMui>
    </ThemeContext.Provider>
  )
}
export default ThemeProvider;
